<template>
    <div>
      <b-card :title="t('検索設定')" class="mb-3">

        <b-form-group :label="t('商品リサーチ詳細検索の既定のページ当たり件数')">
          <b-form-select v-model="selectedCountPerPage">
            <b-form-select-option 
              v-for="(option, index) in countPerPages" 
              :value="option" 
              :key="index">
                {{ t(option + "件") }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group :label="t('カテゴリーランキングTOP1000の既定の期間')">
          <b-form-select v-model="selectedRankingRange">
            <b-form-select-option 
              v-for="(option, index) in rankingRange" 
              :value="index" 
              :key="index">
                {{ t(option) }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-button class="bg-eresa text-white" @click="saveSettings">{{ t('保存') }}</b-button>
      </b-card>
      <loading-screen v-if="loadingScreen"></loading-screen>
    </div>
    
</template>

<script>

import Utils from "@/mixins/utils";
import LoadingScreen from "@/components/LoadingScreen.vue";

export default {
  name: 'SearchConditions',
  components: {
    LoadingScreen
  },
  props: [],
  mixins: [Utils],
  data() {
    return {
      selectedItemCount: null,
      countPerPages: [10, 50, 100, 150, 200],
      selectedCountPerPage: null,
      selectedRankingRange: null,
      rankingRange: ['現在ランク', '過去30日平均', '過去90日平均', '過去180日平均'],
      loadingScreen: false
    }
  },
  watch: {
  },
  computed: {
  },
  async created() {
    // 検索条件の初期値設定
    this.selectedCountPerPage = 50;
    this.selectedRankingRange = 0;
  },
  async mounted() {
    const searchConditions = await this.$store.getters.getSearchConditions;

    //設定が存在する場合は設定を反映
    if (searchConditions) {
      this.selectedCountPerPage = searchConditions.defaultCountPerPage;
      this.selectedRankingRange = searchConditions.defaultRankingRange;
    }
  },
  methods: {
    async saveSettings() {
      const settings = {
        defaultCountPerPage: this.selectedCountPerPage,
        defaultRankingRange: this.selectedRankingRange
      }
      this.loadingScreen = true;

      try {
        await this.$store.dispatch('saveSearchConditions', settings);
      } catch (error) {
        console.error("Error in saveSearchConditions: ", error);
      } finally {
        this.loadingScreen = false;
      }
    },
  }
};
</script>

<style scoped>
</style>
